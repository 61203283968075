export default {
    getUsuario: (state) => state.usuario,
    getListaEstados: (state) => state.estadosBrasileiros || [],
    getListaClientes: (state) => state.listaClientes || [],
    getListaEmpresas: (state) => state.listaEmpresas || [],
    getListaMotoristas: (state) => state.listaMotoristas || [],
    getListaEquipamentos: (state) => state.listaEquipamentos || [],
    getListaVeiculos: (state) => state.listaVeiculos || [],
    getListaLocacoes: (state) => state.listaLocacoes || [],
    getPagination: (state) => state.pagination
  };
  