import apiClient from '../services/axios';
import { login } from '@/services/auth.js';

export default {
    // Actions Login
    async efetuarLogin({ commit }, { email, password, rememberMe }) {
        try {
            const response = await apiClient.post('Usuario/Login', {
                email,
                password,
            });

            const token = response.data.token;
            login(token);

            commit('SET_USUARIO', { nome: email, email });

            if (rememberMe) {
                localStorage.setItem('email', email);
            } else {
                localStorage.removeItem('email');
            }
        } catch (error) {
            throw error.response.data || 'Erro desconhecido ao tentar efetuar login';
        }
    },

    async verificarUsuario(context, email) {
        try {
            const response = await apiClient.post("Usuario/verificarUsuario", {
                email: email.trim(),
            });

            return response;
        }
        catch (error) {
            if (error.response) {
                return error.response;
            } else {
                console.error("Erro ao localizar Usuário:", error);
                throw new Error("Erro ao localizar Usuário.");
            }
        }
    },

    //Actions Paginação
    async resetarPaginacao({commit}){
        commit("SET_PAGINATION", {
            currentPage: 1,
            totalPages:  1,
            pageSize: 10,
            totalItems: 0,
        });
    },

    // Actions Clientes
    async selecionarTodosClientes({ commit }, { pageNumber = 1, pageSize = 10 }) {
        try {
            const response = await apiClient.get("Cliente/SelecionarTodos", {
                params: { pageNumber, pageSize },
            });

            commit('SET_CLIENTES', response.data);

            const paginationData = response.headers.pagination
                ? JSON.parse(response.headers.pagination) : {};

            commit("SET_PAGINATION", {
                currentPage: paginationData.currentPage || 1,
                totalPages: paginationData.totalPages || 1,
                pageSize: paginationData.itemsPerPage || pageSize,
                totalItems: paginationData.totalItems || 0,
            });

            return paginationData;

        } catch (error) {
            console.error("Erro ao buscar clientes:", error.response?.data || error.message);
            throw new Error("Erro ao carregar clientes, tente novamente mais tarde.");
        }
    },

    async selecionarClientesPorTermo({ commit }, { termo, pageNumber = 1, pageSize = 10 }) {
        try {
            const response = await apiClient.get(`Cliente/SelecionarTermo/${termo}`, {
                params: { pageNumber, pageSize },
            });
    
            commit('SET_CLIENTES', response.data);
    
            const paginationData = response.headers.pagination
                ? JSON.parse(response.headers.pagination) : {};
    
            commit("SET_PAGINATION", {
                currentPage: paginationData.currentPage || 1,
                totalPages: paginationData.totalPages || 1,
                pageSize: paginationData.itemsPerPage || pageSize,
                totalItems: paginationData.totalItems || response.data.length,
            });
    
            return response.data;
    
        } catch (error) {
            console.error("Erro ao buscar clientes por termo:", error.response?.data || error.message);
            throw new Error("Erro ao buscar clientes por termo, tente novamente mais tarde.");
        }
    },

    async selecionarClientePorDocumento({ commit }, documento) {
        try {
            const response = await apiClient.get(`Cliente/SelecionarDocumento/${documento}`);
            commit('SET_CLIENTE', response.data);
            return response.data;
        } catch (error) {
            console.error('Erro ao buscar cliente por documento:', error);
            throw error;
        }
    },

    async selecionarClientePorId({ commit }, clienteId) {
        try {
            const response = await apiClient.get(`Cliente/${clienteId}`);
            commit('SET_CLIENTE', response.data);
            return response.data;
        } catch (error) {
            console.error('Erro ao carregar o cliente:', error);
            throw error;
        }
    },

    async incluirCliente({ commit }, clienteDTO) {
        try {
            const response = await apiClient.post('Cliente/Incluir', clienteDTO);
            commit('ADICIONAR_CLIENTE', response.data);
            return response.data;
        } catch (error) {
            console.error('Erro ao gravar cliente:', error);
            throw error;
        }
    },

    async alterarCliente({ commit }, clienteDTO) {
        try {
            const response = await apiClient.put('Cliente/Alterar', clienteDTO);
            commit('ATUALIZAR_CLIENTE', response.data);
            return response.data;
        } catch (error) {
            console.error('Erro ao alterar cliente:', error);
            throw error;
        }
    },

    //Actions Empresa
    async selecionarEmpresaPorId({ commit }, empresaId) {
        try {
            const response = await apiClient.get(`Empresa/${empresaId}`);
            commit('SET_EMPRESA', response.data);
            return response.data;
        } catch (error) {
            console.error('Erro ao selecionar a empresa:', error);
            throw error;
        }
    },

    async incluirEmpresa({ commit }, empresaDTO) {
        try {
            const response = await apiClient.post('Empresa/Incluir', empresaDTO);
            commit('ADICIONAR_EMPRESA', response.data);
            return response.data;
        } catch (error) {
            console.error('Erro ao gravar empresa:', error);
            throw error;
        }
    },

    async alterarEmpresa({ commit }, empresaDTO) {
        try {
            const response = await apiClient.put('Empresa/Alterar', empresaDTO);
            commit('ATUALIZAR_EMPRESA', response.data);
            return response.data;
        } catch (error) {
            console.error('Erro ao alterar empresa:', error);
            throw error;
        }
    },

    //Actions Motoristas
    async selecionarTodosMotoristas({ commit }) {
        try {
            const response = await apiClient.get('Motorista/SelecionarTodos');
            commit('SET_MOTORISTAS', response.data);
        } catch (error) {
            console.error('Erro ao buscar motoristas:', error);
            throw error;
        }
    },

    async selecionarMotoristasPorNome({ commit }, nome) {
        try {
            const response = await apiClient.get(`Motorista/nome/${nome}`);
            commit('SET_MOTORISTAS', response.data);
            return response.data;
        } catch (error) {
            console.error('Erro ao buscar motoristas por nome:', error);
            throw error;
        }
    },

    async selecionarMotoristaPorDocumento({ commit }, documento) {
        try {
            const response = await apiClient.get(`Motorista/SelecionarDocumento/${documento}`);
            commit('SET_MOTORISTA', response.data);
            return response.data;
        } catch (error) {
            console.error('Erro ao buscar motorista por documento:', error);
            throw error;
        }
    },

    async selecionarMotoristaPorId({ commit }, motoristaId) {
        try {
            const response = await apiClient.get(`Motorista/${motoristaId}`);
            commit('SET_MOTORISTA', response.data);
            return response.data;
        } catch (error) {
            console.error('Erro ao carregar o motorista:', error);
            throw error;
        }
    },

    async incluirMotorista({ commit }, motoristaDTO) {
        try {
            const response = await apiClient.post('Motorista/Incluir', motoristaDTO);
            commit('ADICIONAR_MOTORISTA', response.data);
            return response.data;
        } catch (error) {
            console.error('Erro ao gravar motorista:', error);
            throw error;
        }
    },

    async alterarMotorista({ commit }, motoristaDTO) {
        try {
            const response = await apiClient.put('Motorista/Alterar', motoristaDTO);
            commit('ATUALIZAR_MOTORISTA', response.data);
            return response.data;
        } catch (error) {
            console.error('Erro ao alterar motorista:', error);
            throw error;
        }
    },

    //Actions Equipamentos
    async selecionarTodosEquipamentos({ commit }) {
        try {
            const response = await apiClient.get('Equipamento/SelecionarTodos');
            commit('SET_EQUIPAMENTOS', response.data);
        } catch (error) {
            console.error('Erro ao buscar equipamentos:', error);
            throw error;
        }
    },

    async selecionarEquipamentosPorNome({ commit }, nome) {
        try {
            const response = await apiClient.get(`Equipamento/SelecionarNome/${nome}`);
            commit('SET_EQUIPAMENTOS', response.data);
            return response.data;
        } catch (error) {
            console.error('Erro ao buscar equipamento por nome:', error);
            throw error;
        }
    },

    async selecionarEquipamentosPorTermo({ commit }, { nome, situacao }) {
        try {
            console.log(nome, situacao);
            const response = await apiClient.get('Equipamento/SelecionarTermo', {
                params: {
                    nome,
                    situacao
                }
            });
            commit('SET_EQUIPAMENTOS', response.data);
            return response.data;
        } catch (error) {
            console.error('Erro ao buscar equipamento:', error);
            throw error;
        }
    },


    async selecionarEquipamentoPorId({ commit }, equipamentoId) {
        try {
            const response = await apiClient.get(`Equipamento/${equipamentoId}`);
            commit('SET_EQUIPAMENTO', response.data);
            return response.data;
        } catch (error) {
            console.error('Erro ao carregar o equipamento:', error);
            throw error;
        }
    },

    async incluirEquipamento({ commit }, equipamentoDTO) {
        try {
            const response = await apiClient.post('Equipamento/Incluir', equipamentoDTO);
            commit('ADICIONAR_EQUIPAMENTO', response.data);
            return response.data;
        } catch (error) {
            console.error('Erro ao gravar equipamento:', error);
            throw error;
        }
    },

    async alterarEquipamento({ commit }, equipamentoDTO) {
        try {
            const response = await apiClient.put('Equipamento/Alterar', equipamentoDTO);
            commit('ATUALIZAR_EQUIPAMENTO', response.data);
            return response.data;
        } catch (error) {
            console.error('Erro ao alterar equipamento:', error);
            throw error;
        }
    },

    //Actions Veículos
    async selecionarTodosVeiculos({ commit }, { pageNumber = 1, pageSize = 10 }) {
        try {
            const response = await apiClient.get("Veiculo/SelecionarTodos", {
                params: { pageNumber, pageSize },
            });

            commit("SET_VEICULOS", response.data);

            const paginationData = response.headers.pagination
                ? JSON.parse(response.headers.pagination) : {};

            commit("SET_PAGINATION", {
                currentPage: paginationData.currentPage || 1,
                totalPages: paginationData.totalPages || 1,
                pageSize: paginationData.itemsPerPage || pageSize,
                totalItems: paginationData.totalItems || 0,
            });

            return paginationData;

        } catch (error) {
            console.error("Erro ao buscar veículos:", error.response?.data || error.message);
            throw new Error("Erro ao carregar veículos, tente novamente mais tarde.");
        }
    },
    
    async selecionarVeiculosPorTermo({ commit }, { termo, pageNumber = 1, pageSize = 10 }) {
        try {
            const response = await apiClient.get(`Veiculo/SelecionarTermo/${termo}`, {
                params: { pageNumber, pageSize },
            });
    
            commit("SET_VEICULOS", response.data);
    
            const paginationData = response.headers.pagination
                ? JSON.parse(response.headers.pagination) : {};
    
            commit("SET_PAGINATION", {
                currentPage: paginationData.currentPage || 1,
                totalPages: paginationData.totalPages || 1,
                pageSize: paginationData.itemsPerPage || pageSize,
                totalItems: paginationData.totalItems || response.data.length,
            });
    
            return response.data;
    
        } catch (error) {
            console.error("Erro ao buscar veículo por termo:", error.response?.data || error.message);
            throw new Error("Erro ao buscar veículos por termo, tente novamente mais tarde.");
        }
    },
    

    async selecionarVeiculoPorId({ commit }, veiculoId) {
        try {
            const response = await apiClient.get(`Veiculo/${veiculoId}`);
            commit('SET_VEICULO', response.data);
            return response.data;
        } catch (error) {
            console.error('Erro ao carregar o veiculo:', error);
            throw error;
        }
    },

    async incluirVeiculo({ commit }, veiculoDTO) {
        try {
            const response = await apiClient.post('Veiculo/Incluir', veiculoDTO);
            commit('ADICIONAR_VEICULO', response.data);
            return response.data;
        } catch (error) {
            console.error('Erro ao gravar veiculo:', error);
            throw error;
        }
    },

    async alterarVeiculo({ commit }, veiculoDTO) {
        try {
            const response = await apiClient.put('Veiculo/Alterar', veiculoDTO);
            commit('ATUALIZAR_VEICULO', response.data);
            return response.data;
        } catch (error) {
            console.error('Erro ao alterar veiculo:', error);
            throw error;
        }
    },


    //Actions Locações
    async selecionarTodasLocacoes({ commit }) {
        try {
            const response = await apiClient.get('Locacao/SelecionarTodos');
            commit('SET_LOCACOES', response.data);
        } catch (error) {
            console.error('Erro ao buscar locações:', error);
            throw error;
        }
    },

    async selecionarLocacoesPorTermo({ commit }, termo) {
        try {
            const response = await apiClient.get(`Locacao/SelecionarPorTermo/${termo}`);
            commit('SET_LOCACOES', response.data);
            return response.data;
        } catch (error) {
            console.error('Erro ao buscar locação:', error);
            throw error;
        }
    },

    async selecionarLocacaoPorId({ commit }, locacaoId) {
        try {
            const response = await apiClient.get(`Locacao/${locacaoId}`);
            commit('SET_LOCACAO', response.data);
            return response.data;
        } catch (error) {
            console.error('Erro ao carregar o locação:', error);
            throw error;
        }
    },

    async incluirLocacao({ commit }, locacaoDTO) {
        try {
            const response = await apiClient.post('Locacao/Incluir', locacaoDTO);
            commit('ADICIONAR_LOCACAO', response.data);
            return response.data;
        } catch (error) {
            console.error('Erro ao gravar locação:', error);
            throw error;
        }
    },

    async alterarLocacao({ commit }, veiculoDTO) {
        try {
            const response = await apiClient.put('Locacao/Alterar', veiculoDTO);
            commit('ATUALIZAR_LOCACAO', response.data);
            return response.data;
        } catch (error) {
            console.error('Erro ao alterar locação:', error);
            throw error;
        }
    },

    async verificarEquipamentoLocado({ commit }, numeroPatrimonio) {
        try {
            const response = await apiClient.get(`Locacao/equipamento-locado/${numeroPatrimonio}`);
            commit('VERIFICAR_EQUIPAMENTO_LOCADO', response.data);
            return response.data;
        } catch (error) {
            console.error('Erro ao verificar se o equipamento está locado:', error);
            throw error;
        }
    },

    //Actions Cep
    async buscarCEP(_, cep) {
        try {
            const response = await apiClient.get(`ViaCep/${cep}`);
            return response.data;
        } catch (error) {
            console.error('Erro ao buscar o CEP:', error);
            throw error;
        }
    },
};
